export const THEME_DAY = 'day';
export const THEME_NIGHT = 'night';

const THEME_ORDER = [THEME_DAY, THEME_NIGHT];

function defaultState() {
  return {
    theme: THEME_DAY,
  };
}

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    SET_THEME(state, payload) {
      state.theme = payload;
      // eslint-disable-next-line no-restricted-syntax
      for (const theme of THEME_ORDER) {
        if (payload === theme) {
          window.document.body.classList.add(`theme-${theme}`);
        } else {
          window.document.body.classList.remove(`theme-${theme}`);
        }
      }
    },
  },
  actions: {
    toggle({
      commit,
      state,
    }) {
      const currentIndex = THEME_ORDER.indexOf(state.theme);
      const nextTheme = THEME_ORDER[currentIndex + 1]
        ? THEME_ORDER[currentIndex + 1]
        : THEME_ORDER[0];
      commit('SET_THEME', nextTheme);
    },
    setDefault({
      commit,
    }) {
      const isDark = window.matchMedia?.('(prefers-color-scheme: dark)') || false;
      commit('SET_THEME', isDark ? THEME_NIGHT : THEME_DAY);
    },
  },
  getters: {},
};
