<script setup>
import { useI18n } from 'vue-i18n';
// import FooterLink from '@/components/footer/FooterLink.vue';
import FooterContactLink from '@/components/footer/FooterContactLink.vue';
import FooterSocialLink from '@/components/footer/FooterSocialLink.vue';

const year = new Date().getFullYear();

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local',
});
</script>

<template>
  <footer class="container footer">
    <div class="footer__row-separate"/>

    <div class="footer__wrapper">
      <div class="footer__block footer__block--start">
        <div class="footer__info">
        <div>{{ t('distributor') }}</div>
        <div class="footer__company">HRToolbox OÜ</div>
          </div>
      </div>
      <div class="footer__block footer__block--social">
        <div class="footer__social-links">
          <FooterSocialLink
            link="https://www.youtube.com/channel/UC_ce-IPSYnXUaR4jaI4tgWg"
            icon="youtube"
          />
          <FooterSocialLink
            link="https://www.linkedin.com/company/furyferret/"
            icon="linkedin"
          />
          <FooterSocialLink
            link="#"
            icon="facebook"
          />
          <FooterSocialLink
            link="#"
            icon="instagram"
          />
          <FooterSocialLink
            link="https://t.me/furyferret"
            icon="telegram"
          />
        </div>
      </div>
      <div class="footer__block footer__block--end">
        <div>
          <FooterContactLink
            description="mail"
            label="blackbox@furyferret.com"
            link="mailto:blackbox@furyferret.com"
          />
          <FooterContactLink
            rel="nofollow"
            target="_blank"
            link="https://t.me/andreisoroka"
            description="t-me"
            label="@andreisoroka"
          />
        </div>
      </div>
    </div>
  </footer>
  <div class="footer-copyright">
    {{ t('copyright', { year }) }}
  </div>
</template>

<style scoped lang="scss">
@import "~@/assets/styles/mixins";

.footer {
  padding-top: 75px;
  color: var(--text-default);
  font-size: 0.8rem;
  line-height: 1.1rem;
}

.footer__block {
  @media (max-width: $screen-max-sm) {
    text-align: center;
  }

  &--start {
    grid-area: start;
  }

  &--end {
    grid-area: end;
  }

  &--social {
    grid-area: social;
  }
}

.footer__row-separate {
  width: 100%;
  height: 1px;
  background: var(--arrow-color);
  margin: auto 0;
}

.footer__links {
  display: grid;
  justify-content: center;
  align-items: flex-start;
}

.footer__social-links {
  display: flex;
  grid-column-gap: 10px;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
  max-width: fit-content;
  margin: 0 auto;
}

.footer__info {
  text-align: right;

  @media (max-width: $screen-max-sm) {
    text-align: center;
  }
}

.footer__company {
  color: var(--text-special);
}

.footer__wrapper {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 20px;
  padding-top: 2rem;
  grid-template-areas: "start social end";

  @media (max-width: $screen-md) {
    grid-template-columns: 1fr 1fr;
    padding: 2rem;
    grid-template-areas:
    "start end"
    "social social";
  }

  @media (max-width: $screen-max-sm) {
    grid-template-columns: 1fr;
    padding: 2rem;
    grid-template-areas:
    "start"
    "social"
    "end";
  }
}

.footer-copyright {
  position: relative;
  background: var(--footer-background);
  text-align: center;
  padding: 2rem;
  margin-top: 5rem;
  color: var(--light-gray);
  font-size: 0.8rem;

  @media (max-width: $screen-md) {
    margin-top: 2rem;
  }

  &:after {
    position: absolute;
    content: ' ';
    background: var(--footer-background);
    width: 3rem;
    height: 3rem;
    border-radius: 3rem;
    left: 50%;
    margin-left: -1.5rem;
    top: -1rem;
  }
}
</style>

<i18n>
{
  "en": {
    "distributor": "Application Distributor",
    "copyright": "© 2019 - {year} FuryFerret. All rights reserved."
  },
  "ru": {
    "distributor": "Дистрибьютор приложения",
    "copyright": "© 2019 - {year} FuryFerret. Все права защищены."
  }
}
</i18n>
