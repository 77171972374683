<script setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import IconInfoSmall from '../icons/IconInfoSmall.vue';
import i18n from '@/i18n';

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const refDownloadSectionTitle = ref(null);

defineExpose({
  refDownloadSectionTitle,
});

const props = defineProps({
  windowsLink: {
    type: String,
    required: true,
  },
  macOsLink: {
    type: String,
    required: true,
  },
  version: {
    type: String,
    required: true,
  },
  release: {
    type: Number,
    required: true,
  },
});

function download(link) {
  window.location = link;
}

const buttons = computed(() => [
  {
    type: 'windows',
    link: props.windowsLink,
    file: '.exe',
  },
  {
    type: 'darwin',
    link: props.macOsLink,
    file: '.dmg',
  },
]);
const releaseDate = computed(() => new Intl.DateTimeFormat(i18n.global.locale)
  .format(new Date(props.release)));
</script>

<template>
  <section class="container">
    <h3
      ref="refDownloadSectionTitle"
      class="title"
    >
      {{ t('title') }}
    </h3>
    <a
      v-for="button in buttons"
      :key="button.type"
      :href="button.link"
      download
      class="download__button-container"
      :class="`--${button.type}`"
    >
      <button class="download__button">
        {{ t('download') }} {{ button.file }}
      </button>
    </a>
    <span class="download__footer">
      <IconInfoSmall class="download__icon-info"/>
      {{
        t('info', {
          version,
          releaseDate,
        })
      }}
    </span>
  </section>
</template>

<style scoped lang="scss">
@import "~@/assets/styles/mixins";

.download__button-container {
  display: block;
  width: 100%;
  max-width: 270px;
  position: relative;
  height: 44px;
  margin: 0 auto 20px;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    width: 44px;
    height: 44px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  &.--windows:before {
    background-image: url("~@/assets/logo/windows.svg");
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position: center;

    @include isThemeNight {
      background-image: url("~@/assets/logo/windows-dark.svg");
      background-size: 35px 35px;
    }
  }

  &.--darwin:before {
    background-image: url("~@/assets/logo/apple.svg");
    background-size: 35px 44px;
    background-repeat: no-repeat;
    background-position: center;

    @include isThemeNight {
      background-image: url("~@/assets/logo/apple-dark.svg");
      background-size: 35px 35px;
    }
  }

  &:hover .download__button {
    background-color: var(--button-background-color-hover);
    width: 210px;
    transform: translateX(-0.85);
  }
}

.download__button {
  transition: 200ms;
  height: 44px;
  border-radius: 2px;
  color: #FEFBFB;
  background-color: var(--button-background-color);
  border: none;
  font-size: 15px;
  position: absolute;
  right: 0;
  width: 270px;
  cursor: pointer;
  text-shadow: var(--shadow-on-block);
}

.download__footer {
  font-size: 14px;
  line-height: 18px;
  color: var(--text-special);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.download__icon-info {
  fill: var(--button-background-color-hover);
  width: 23px;
  height: 23px;
  margin-right: 12px;

  @include isThemeNight {
    fill: #7B7B7B;
  }
}

</style>

<i18n>
{
  "en": {
    "title": "Download Recruitment tools",
    "download": "Download",
    "info": "Version {version}. Release {releaseDate}"
  },
  "ru": {
    "title": "Скачать приложение",
    "download": "Скачать",
    "info": "Версия {version}. Дата выхода {releaseDate}"
  }
}
</i18n>
