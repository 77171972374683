export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Recruitment tools"])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
        "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Version ", _interpolate(_named("version")), ". Release ", _interpolate(_named("releaseDate"))])}
      },
      "ru": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать приложение"])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать"])},
        "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Версия ", _interpolate(_named("version")), ". Дата выхода ", _interpolate(_named("releaseDate"))])}
      }
    }
  })
}
