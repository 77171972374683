<script setup>
import Cookies from 'js-cookie';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from '@/components/Button.vue';
import Checkbox from '@/components/Checkbox.vue';
import YandexMetrica from '@/components/cookies/YandexMetrica.vue';

const $t = useI18n({}).t;
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const IS_READ_COOKIE = 'ff-cookies_is-read';
const ANALYTICAL = 'ff-cookies_analytical';
const MARKETING = 'ff-cookies_marketing';
const OTHER = 'ff-cookies_other';

const TRUE = '1';
const FALSE = '0';

const isShowSettings = ref(Cookies.get(IS_READ_COOKIE));
const isReadCookies = ref(Cookies.get(IS_READ_COOKIE));

function handleAcceptAll() {
  isReadCookies.value = true;
  Cookies.set(IS_READ_COOKIE, TRUE);
  Cookies.set(ANALYTICAL, TRUE);
  Cookies.set(MARKETING, TRUE);
  Cookies.set(OTHER, TRUE);
}

const cookiesList = ref({
  necessary: {
    value: true,
    disabled: true,
    label: 'cookies.policy.necessary.label',
    description: 'cookies.policy.necessary.description',
  },
  [ANALYTICAL]: {
    value: Cookies.get(ANALYTICAL) !== FALSE,
    label: 'cookies.policy.analytical.label',
    description: 'cookies.policy.analytical.description',
  },
  [MARKETING]: {
    value: Cookies.get(MARKETING) !== FALSE,
    label: 'cookies.policy.marketing.label',
    description: 'cookies.policy.marketing.description',
  },
  [OTHER]: {
    value: Cookies.get(OTHER) !== FALSE,
    label: 'cookies.policy.other.label',
    description: 'cookies.policy.other.description',
  },
});

function handleForm() {
  isReadCookies.value = true;
  Cookies.set(IS_READ_COOKIE, TRUE);
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in cookiesList.value) {
    const { value, disabled } = cookiesList.value[key];
    if (!disabled) {
      Cookies.set(key, value ? TRUE : FALSE);
    }
  }
}
</script>

<template>
  <div
    v-if="!isReadCookies"
    class="cookie-first-modal"
  >
    <div
      v-show="!isShowSettings"
      class="cookie-first-modal__content"
    >
      {{ $t('cookies.description') }}
    </div>
    <div
      v-show="isShowSettings"
      class="cookie-first-modal__content cookie-first-modal__content--block"
    >
      <div
        v-for="(item, name) in cookiesList"
        :key="name"
      >
        <Checkbox
          :name="name"
          :value="item.value"
          :disabled="item.disabled"
          :label="$t(item.label)"
          @input="item.value = $event"
        />
        <div class="cookie-first-modal__description">
          {{ $t(item.description) }}
        </div>
      </div>
    </div>

    <div class="cookie-first-modal__controls">
      <Button
        @click="handleAcceptAll"
      >
        {{ t('buttonAcceptAll') }}
      </Button>
      <Button
        v-show="!isShowSettings"
        @click="isShowSettings=true"
      >
        {{ t('buttonCookieSettings') }}
      </Button>
      <Button
        v-show="isShowSettings"
        @click="handleForm"
      >
        {{ t('buttonChoiceSelectedCookies') }}
      </Button>
    </div>
  </div>
  <template v-else>
    <YandexMetrica v-if="cookiesList[ANALYTICAL].value"/>
  </template>
</template>

<style lang="scss" scoped>
@import "~@/assets/styles/mixins";

.cookie-first-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--background-cookie-wrapper);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: white;
}

.cookie-first-modal__controls {
  display: flex;
  gap: 15px;
}

.cookie-first-modal__description {
  margin: 5px 0 20px;
}

.cookie-first-modal__content {
  overflow: auto;
  max-height: 50vh;

  &--block {
    background: var(--background);
    color: var(--text-default);
    padding: 20px;
    border-radius: 8px;
  }
}
</style>

<i18n>
{
  "en": {
    "buttonAcceptAll": "Accept All",
    "buttonCookieSettings": "Cookie Settings",
    "buttonChoiceSelectedCookies": "Choice Selected Cookies"
  },
  "ru": {
    "buttonAcceptAll": "Принять все",
    "buttonCookieSettings": "Настройки cookie",
    "buttonChoiceSelectedCookies": "Принять выбранные"
  }
}
</i18n>
